/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


$(document).ready(function () {
    // Add smooth scrolling to all links in navbar + footer link
    $(".navbar a, footer a[href='#myPage'],.jumbotron a").on('click', function (event) {
        // Make sure this.hash has a value before overriding default behavior
        var current_location=$.trim(window.location.hash) ? window.location.href.substr(0,window.location.href.indexOf('#')) : window.location.href;
        if (this.hash !== "" && (this.href.substr(0,this.href.indexOf('#'))==current_location)) {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 900, function () {

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });
        } // End if
    });

    $(window).scroll(function () {
        $(".slideanim").each(function () {
            var pos = $(this).offset().top;

            var winTop = $(window).scrollTop();
            if (pos < winTop + 600) {
                $(this).addClass("slide");
            }
        });
    });
});

$(document).ready(function () {

if ($('.selectChild:checked').length == $('.selectChild').length) {
    $('#selectAll').prop('checked', true);
} else {
    $('#selectAll').prop('checked', false);
}


$('#selectAll').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild').prop('checked', true);
    } else {
        $('.selectChild').prop('checked', false);
    }
});

$('.selectChild').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild:checked').length == $('.selectChild').length) {
        $('#selectAll').prop('checked', true);
    } else {
        $('#selectAll').prop('checked', false);
    }
});

if ($('.selectChild1:checked').length == $('.selectChild1').length) {
    $('#selectAll1').prop('checked', true);
} else {
    $('#selectAll1').prop('checked', false);
}

$('#selectAll1').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild1').prop('checked', true);
    } else {
        $('.selectChild1').prop('checked', false);
    }
});

$('.selectChild1').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild1:checked').length == $('.selectChild1').length) {
        $('#selectAll1').prop('checked', true);
    } else {
        $('#selectAll1').prop('checked', false);
    }
});

if ($('.selectChild2:checked').length == $('.selectChild2').length) {
    $('#selectAll2').prop('checked', true);
} else {
    $('#selectAll2').prop('checked', false);
}


$('#selectAll2').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild2').prop('checked', true);
    } else {
        $('.selectChild2').prop('checked', false);
    }
});

$('.selectChild2').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild2:checked').length == $('.selectChild2').length) {
        $('#selectAll2').prop('checked', true);
    } else {
        $('#selectAll2').prop('checked', false);
    }
});

if ($('.selectChild3:checked').length == $('.selectChild3').length) {
    $('#selectAll3').prop('checked', true);
} else {
    $('#selectAll3').prop('checked', false);
}


$('#selectAll3').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild3').prop('checked', true);
    } else {
        $('.selectChild3').prop('checked', false);
    }
});

$('.selectChild3').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild3:checked').length == $('.selectChild3').length) {
        $('#selectAll3').prop('checked', true);
    } else {
        $('#selectAll3').prop('checked', false);
    }
});

if ($('.selectChild4:checked').length == $('.selectChild4').length) {
    $('#selectAll4').prop('checked', true);
} else {
    $('#selectAll4').prop('checked', false);
}


$('#selectAll4').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild4').prop('checked', true);
    } else {
        $('.selectChild4').prop('checked', false);
    }
});

$('.selectChild4').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild4:checked').length == $('.selectChild4').length) {
        $('#selectAll4').prop('checked', true);
    } else {
        $('#selectAll4').prop('checked', false);
    }
});


if ($('.selectChild5:checked').length == $('.selectChild5').length) {
    $('#selectAll5').prop('checked', true);
} else {
    $('#selectAll5').prop('checked', false);
}


$('#selectAll5').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild5').prop('checked', true);
    } else {
        $('.selectChild5').prop('checked', false);
    }
});

$('.selectChild5').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild5:checked').length == $('.selectChild5').length) {
        $('#selectAll5').prop('checked', true);
    } else {
        $('#selectAll5').prop('checked', false);
    }

});

if ($('.selectChild6:checked').length == $('.selectChild6').length) {
    $('#selectAll6').prop('checked', true);
} else {
    $('#selectAll6').prop('checked', false);
}


$('#selectAll6').click(function () {
    if ($(this).prop('checked')) {
        $('.selectChild6').prop('checked', true);
    } else {
        $('.selectChild6').prop('checked', false);
    }
});

$('.selectChild6').click(function (e) {
    e.stopPropagation();
    if ($('.selectChild6:checked').length == $('.selectChild6').length) {
        $('#selectAll6').prop('checked', true);
    } else {
        $('#selectAll6').prop('checked', false);
    }
});

$('.innerBtn').click(function (e) {
    e.stopPropagation();
});

$('.innerBtn2').click(function (e) {
    e.stopPropagation();
});

});

$(function () {
    var hash = $.trim(window.location.hash); 
    if (hash){
        $('.panel a[href$="' + hash + '"]').trigger('click');
    $('.nav-tabs a[href$="' + hash + '"]').trigger('click');  

    var scroll_offset = (hash.indexOf('_tab')==-1) ? 1 : -250;
    var scroll_time = (hash.indexOf('_tab')==-1) ? 1 : 900;

    setTimeout(function() {
    $('html, body').animate({
       scrollTop: $(hash).offset().top+scroll_offset
    }, scroll_time)}, 150) ; 
    document.location.href= hash;
}

});

$(document).ready(function(){
  $('.dropdown-submenu a.sub-drop').on("click", function(e){
    $(this).next('ul').toggle();
    e.stopPropagation();
    e.preventDefault();
  });
});
